import React, { ReactNode, useEffect, useState } from "react"
import { auth } from "../util/config/firebase"
import { User, onAuthStateChanged } from "firebase/auth"

const AuthContext = React.createContext({})

const AuthProvider = ({ children } : { children : ReactNode}) => {
    const [currentUser, setCurrentUser] = useState<User | null>()
    const [isloading , setIsLoading] = useState(true)

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
                setCurrentUser(user)
                setIsLoading(false)
            })
    }, [])

    return (
        <AuthContext.Provider value={[currentUser]}>
            {!isloading && children}
        </AuthContext.Provider>
    )
}

export default AuthProvider