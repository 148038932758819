import React, { forwardRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { Card, CardHeader, Typography, Divider, CardContent } from '@mui/material'
import { type CardInterface } from '../../../util/interface/CardInterface'

const header = {
  '& .MuiCardHeader-action': { mr: 0 }
}

const MainCard = forwardRef<HTMLDivElement, CardInterface>(
  function MainCard({
    border = true,
    boxShadow,
    children,
    content = true,
    contentClass = '',
    contentSX = {},
    darkTitle,
    secondary,
    shadow,
    customSx = {},
    title,
    ...others
  },
    ref) {
    const theme = useTheme()

    return (
      <Card
        ref={ref}
        {...others}
        className='maincard'
        sx={{
          //border: border ? '1px solid' : 'none',
          //borderColor: theme.palette.primary.weak + 25,
          borderRadius: '12px',
          ':hover': {
            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
          },
          ...customSx
        }}
      >
        {title &&
          (<CardHeader
            sx={header}
            title={darkTitle ? <Typography variant='h3'>{title}</Typography> : title}
            action={secondary} />
          )}
        {title && <Divider />}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    )
  })

export default MainCard
