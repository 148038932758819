import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from '../redux/api/apiSlice'
import mainLayoutSlice from '../redux/mainLayout/mainLayoutSlice'
import transactionRecordSlice from '../redux/transactionRecord/transactionRecordSlice'
import assetsSlice from '../redux/assets/assetsSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    mainLayout: mainLayoutSlice,
    transactionRecord: transactionRecordSlice,
    assets: assetsSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
