import FiberManualRecord from '@mui/icons-material/FiberManualRecord'
import { Chip, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'
//import useMediaQuery from '@mui/material/useMediaQuery'
import React, { forwardRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { type CollapseInterface } from '../../../../../util/interface/CollapseInterface'
import { useDispatch } from 'react-redux'
import { enteringPage } from '../../../../../redux/mainLayout/mainLayoutSlice'

const NavItem = ({ item, level }: { item: CollapseInterface, level: number }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const handleTitleChange = (title: string) => {
    dispatch(enteringPage(title))
  }
  //const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))

  const itemIcon = item?.icon
    ? (
      <item.icon stroke={1.5} size='1.3rem' />
    )
    : (
      <FiberManualRecord
        sx={{
          width: 8,
          height: 8
        }}
        fontSize={level > 0 ? 'inherit' : 'medium'}
      />
    )

  let itemTarget = '_self'
  if (item.target)
  {
    itemTarget = '_blank'
  }

  const listItemProps = {
    component: forwardRef(
      function listItem(props, ref: React.Ref<HTMLAnchorElement>) {
        return <Link ref={ref} {...props} to={item.url ? item.url : ''} target={itemTarget} />
      })
  }

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id: string) => id === item.id)
    if (currentIndex > -1)
    {

    }
  }, [item.id])

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: '12px',
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`
      }}
      selected={false}
      onClick={() => { handleTitleChange(item.title) }}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography variant='h6' color="inherit">
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {(item.chip != null) && (
        <Chip
          color={item.chip.props.color}
          variant={item.chip.props.variant}
          size={item.chip.props.size}
          label={item.chip.props.label}
          avatar={item.chip.props.avatar && <Avatar>{item.chip.props.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  )
}

export default NavItem
