import React, { useEffect } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Route from './route'
import AuthProvider from './context/AuthContext'

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } })

const App = (): JSX.Element => {
  const customization = {
    navType: 'dark',
    borderRadius: '12'
  }

  useEffect(() => {
     const existingPreference = localStorage.getItem("mode")
     if(existingPreference) {
      (existingPreference === 'light') ? setMode('light') : setMode('dark')
     } else {
      setMode('light')
      localStorage.setItem("mode", "light")
     }
  },[])

  const [mode, setMode] = React.useState<'light' | 'dark'>('dark')
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((preMode) => (preMode === 'light' ? 'dark' : 'light'))
        localStorage.setItem('mode', mode)
      }
    }),
    [],
  )

  console.log(colorMode)

  const theme = React.useMemo(
    () =>
      createTheme({
        custom: {
          primary: "#1e88e5",
          secondary: "#e17301"
        },
        palette: {
          mode,
          background: {
            default: '#f3f4f9'
          },
          ...(mode === 'dark' && {
            background: {
              default: '#0d1117',
            }
          })
        },
      }),
    [mode],
  )

  return (
    <ColorModeContext.Provider value={colorMode}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Route />
          </AuthProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ColorModeContext.Provider>
  )
}

export default App
