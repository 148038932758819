import { type CardtypeInterface } from '../interface/CardTypeInterface'
import { type colors, type themeInterface } from '../interface/themeInterface'

export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320

const defalutColors: colors = {
  darkPaper: '#111936',
  darkTextPrimary: '#bdc8f0',
  darkLevel1: '#29314f',
  darkLevel2: '#212946',
  darkBackground: '#1a223f',
  grey50: '#F8FAFC',
  grey100: '#EEF2F6',
  grey200: '#E3E8EF',
  grey300: '#CDD5DF',
  grey500: '#697586',
  grey600: '#4B5565',
  grey700: '#364152',
  grey900: '#121926',
  primary: {
    Light: '#eef2f6',
    Main: '#2196f3',
    Dark: '#1e88e5',
    weak: '#90caf9',
    strong: '#1565c0'
  },
  secondary: {
    Light: '#ede7f6',
    Main: '#673ab7',
    Dark: '#0000008c',
    weak: '#0000000d',
    strong: '#4527a0'
  },
  error: {
    Light: '#ef9a9a',
    Main: '#f44336',
    Dark: '#c62828'
  },
  orange: {
    Light: '#fbe9e7',
    Main: '#ffab91',
    Dark: '#d84315'
  },
  warning: {
    Light: '#fff8e1',
    Main: '#ffe57f',
    Dark: '#ffc107'
  },
  success: {
    Light: '#b9f6ca',
    Main: '#69f0ae',
    Dark: '#00e676',
    weak: '#00c853'
  }
}

export const defalutTheme: themeInterface = {
  colors: defalutColors,
  heading: '#121926',
  paper: '#ffffff',
  backgroundDefault: '#ffffff',
  background: '#ffffff',
  darkTextPrimary: '#364152',
  darkTextSecondary: '#697586',
  textDark: '#121926',
  menuSelected: '#651fff',
  menuSelectedBack: '#ede7f6',
  divider: '#E3E8EF'
}

export const darkTheme: themeInterface = {
  colors: defalutColors,
  heading: '#ffffff',
  paper: '#0f0f0f',
  backgroundDefault: '#0f0f0f',
  background: '#0f0f0f',
  darkTextPrimary: '#364152',
  darkTextSecondary: '#697586',
  textDark: '#121926',
  menuSelected: '#651fff',
  menuSelectedBack: '#ede7f6',
  divider: '#E3E8EF'
}

export const primaryCardTheme: CardtypeInterface = {
  content: 'Top Spent',
  backgroundColor: '#1e88e5',
  background: '#1565c0',
  color: '#90caf9',
  avatar: {
    bgcolors: ['#1565c0', '#90caf9'],
    colors: '#90caf9'
  }
}

export const secondaryCardTheme: CardtypeInterface = {
  content: 'Total Spent',
  backgroundColor: '#5e35b1',
  background: '#4527a0',
  color: '#b39ddb',
  avatar: {
    bgcolors: ['#4527a0', '#b39ddb'],
    colors: '#b39ddb'
  }
}

export const greenCardTheme: CardtypeInterface = {
  content: 'Test',
  backgroundColor: '#30d782',
  background: '#2c976b',
  color: '#43b184',
  avatar: {
    bgcolors: ['#107a2c'],
    colors: '#107a2c'
  }
}

export const orangeCardTheme: CardtypeInterface = {
  content: 'Test',
  backgroundColor: '#fd9513',
  background: '#e17301',
  color: '#956737',
  avatar: {
    bgcolors: ['#c45b01'],
    colors: '#fff'
  }
}

export const primaryDarkTheme: CardtypeInterface = {
  content: 'TBC',
  backgroundColor: '#1e88e5',
  background: '#90caf9',
  color: '#eef2f6',
  avatar: {
    bgcolors: ['#1565c0', ''],
    colors: '#fff'
  },
  primaryText: '#fff',
  secondaryText: '#eef2f6'
}

export const primaryLightTheme: CardtypeInterface = {
  content: 'Available Quota',
  backgroundColor: '',
  background: '#ffc107',
  color: '',
  avatar: {
    bgcolors: ['#fff8e1', ''],
    colors: '#ffc107'
  },
  primaryText: '',
  secondaryText: '#697586'
}

export const purpleAssetCardTheme = {
  background: 'linear-gradient(172deg,#9C2CF3,#3A49F9)',
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  filter: 'saturate(85%)',
  bottom: '10px',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 413,
    height: 180,
    background: '#0000001c',
    borderRadius: '80%',
    zIndex: 1,
    top: -110,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 500,
    height: 210,
    background: '#0000001c',
    borderRadius: '50%',
    top: 120,
    right: 270
  }
}

export const redAssetCardTheme = {
  background: 'linear-gradient(172deg,#E42C66,#F55B46)',
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  filter: 'saturate(95%)',
  bottom: '10px',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 413,
    height: 180,
    background: '#0000001c',
    borderRadius: '80%',
    zIndex: 1,
    top: -110,
    right: -180
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 500,
    height: 210,
    background: '#0000001c',
    borderRadius: '50%',
    top: 120,
    right: 270
  }
}

export const pinkAssetCardSnapshotTheme = {
  background: 'linear-gradient(172deg,#E42C66,#F55B46)',
  justifyContent: 'center',
  color: '#fff',
  borderRadius: "12px 12px 0% 0%",
  overflow: 'hidden',
  position: 'relative',
  filter: 'saturate(95%)',
  '&>div': {
    position: 'relative',
    zIndex: 5
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 413,
    height: 180,
    background: '#0000001c',
    borderRadius: '80%',
    zIndex: 1,
    top: -110,
    right: -180
  }
}

export type colorSchemeKey = "Purple" | "Red" | "Default"

export const colorSchemeMapping = {
  "Purple": purpleAssetCardTheme,
  "Red": redAssetCardTheme,
  "Default": purpleAssetCardTheme
}