import dashboard from './dashboard'
// import pages from './pages'
// import utilities from './utilities'
// import others from './others'
import assetSummary from './assetSummary'

const menuItems = {
  items: [dashboard, assetSummary]
}

export default menuItems
