import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { type CollapseInterface } from '../../../util/interface/CollapseInterface'

const icons = { ReceiptLongOutlinedIcon, AccountBalanceIcon }

const assetSummary: CollapseInterface = {
    id: 'assetSummary',
    title: 'Asset Summary',
    type: 'group',
    children: [
        {
            id: 'records',
            title: 'Records',
            type: 'item',
            url: 'records/all',
            icon: icons.ReceiptLongOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'asset',
            title: 'Asset',
            type: 'item',
            url: 'assets/',
            icon: icons.AccountBalanceIcon,
            breadcrumbs: false
        }
    ]
}

export default assetSummary