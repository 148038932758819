import React, { lazy } from 'react'
import MainLayout from '../component/Layout/MainLayout'
import AuthLayout from '../component/Layout/AuthLayout'
import Loadable from '../component/utils/Loadable'
import Error from '../component/utils/ErrorPage'
import { Navigate } from 'react-router-dom'
import { User } from 'firebase/auth'

const DashboardDefault = Loadable(lazy(async () => await import('../component/dashboard')))

const RecordsDefault = Loadable(lazy(async () => await import('../component/records')))

const AssetDefault = Loadable(lazy(async () => await import('../component/asset')))

const SettingDefault = Loadable(lazy(async () => await import('../component/setting')))

const Registration = Loadable(lazy(async () => await import('../component/auth/register/index')))

const Logon = Loadable(lazy(async () => await import('../component/auth/logon/index')))

const MainRoutes = (user: User | null | undefined) => [
  {
    path: '/',
    element: user ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <DashboardDefault />
          }
        ]
      },
      {
        path: 'records',
        children: [
          {
            path: 'all',
            element: <RecordsDefault />
          }
        ]
      },
      {
        path: 'assets',
        children: [
          {
            path: '',
            element: <AssetDefault />
          }
        ]
      },
      {
        path: 'setting',
        children: [
          {
            path: '',
            element: <SettingDefault />
          }
        ]
      },
      {
        path: 'error',
        element: <Error />
      }
    ]
  },
  {
    path: '/',
    element: !user ? <AuthLayout /> : <Navigate to="/dashboard" />,
    children: [
      {
        path: '/login',
        children: [
          {
            path: '',
            element: <Logon />
          }
        ]
      },
      {
        path: 'registration',
        children: [
          {
            path: '',
            element: <Registration />
          }
        ]
      },
      {
        path: 'logon',
        children: [
          {
            path: '',
            element: <Logon />
          }
        ]
      },
    ]
  }
]

export default MainRoutes
