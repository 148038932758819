import { createEntityAdapter, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

interface transactionRecord {
    recordDataMonth: number,
    recordDataYear: number,
    pageNumber: number,
    pageSize: number,
    description: string,
}

const transactionRecordAdapter = createEntityAdapter<transactionRecord>()

const transactionRecordSlice = createSlice({
    name: 'transactionRecord',
    initialState: transactionRecordAdapter.getInitialState({
        recordDataMonth: dayjs().month(),
        recordDataYear: dayjs().year(),
        pageNumber: 0,
        pageSize: 10,
        description: ""
    }),
    reducers: {
        onMonthChange: (state, action: PayloadAction<number>) => {
            state.recordDataMonth = action.payload
        },
        onYearChange: (state, action: PayloadAction<number>) => {
            state.recordDataYear = action.payload
        },
        onDescriptionChange: (state, action: PayloadAction<string>) => {
            state.description = action.payload
        },
        onPageChange: (state, action: PayloadAction<{pageNumber: number, pageSize: number}>) => {
            state.pageNumber = action.payload.pageNumber
            state.pageSize = action.payload.pageSize
        }
    }
})

export const { onMonthChange, onYearChange, onDescriptionChange, onPageChange } = transactionRecordSlice.actions

export default transactionRecordSlice.reducer