import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import FiberManualRecord from '@mui/icons-material/FiberManualRecord'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import NavItem from '../NavItem'
import { type CollapseInterface } from '../../../../../util/interface/CollapseInterface'

const NavCollapse = ({ menu, level }: { menu: CollapseInterface, level: number }) => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string>()

  const handleClick = () => {
    setOpen(!open)
    setSelected(!selected ? menu.id : undefined)
  }

  const { pathname } = useLocation()
  const checkOpenForParent = (child: CollapseInterface[], id: string) => {
    child.forEach((item: CollapseInterface) => {
      if (item.url === pathname) {
        setOpen(true)
        setSelected(id)
      }
    })
  }

  useEffect(() => {
    setOpen(false)
    setSelected('')
    if (menu.children != null) {
      menu.children.forEach((item: CollapseInterface) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id)
        }
        if (item.url === pathname) {
          setSelected(menu.id)
          setOpen(true)
        }
      })
    }
  }, [pathname, menu.children])

  const menus = menu.children?.map((item: CollapseInterface) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />
      default:
        return (
                    <Typography key={item.id} variant='h6' color='error' align='center'>
                        Menu Items Error
                    </Typography>
        )
    }
  })

  const menuIcon = menu.icon
    ? (
        <menu.icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
      )
    : (
        <FiberManualRecord
            sx={{
              width: selected === menu.id ? 8 : 6,
              height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
      )

  return (
        <>
            <ListItemButton
                sx={{
                  borderRadius: '12px',
                  mb: 0.5,
                  alignItems: 'flex-start',
                  backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                  py: level > 1 ? 1 : 1.25,
                  pl: `${level * 24}px`
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color='inherit' sx={{ my: 'auto' }}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography 
                              variant='caption' 
                              sx={{ 
                                fontSize: '0.6875rem',
                                fontWeight: 500,
                                textTransform: 'capitalize' 
                              }} 
                              display='block' 
                              gutterBottom
                            >
                              {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open
                  ? (
                    <KeyboardArrowUpOutlinedIcon style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    )
                  : (
                    <KeyboardArrowDownOutlinedIcon style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    )}
            </ListItemButton>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List
                    component='div'
                    disablePadding
                    sx={{
                      position: 'relative',
                      '&:after': {
                        content: "''",
                        position: 'absolute',
                        left: '32px',
                        top: 0,
                        height: '100%',
                        width: '1px',
                        opacity: 1
                      }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
  )
}

export default NavCollapse
