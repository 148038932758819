import { Box, CssBaseline } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { Outlet } from 'react-router-dom';

const Main = styled('main')(({ theme }) => ({
    ...{
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
    }
}))

const AuthLayout = () => (
    <Box display="flex">
        <CssBaseline />
        <Main>
            <Outlet />
        </Main>
    </Box>
)

export default AuthLayout
