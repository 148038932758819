import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Divider, Drawer, IconButton, useMediaQuery } from '@mui/material'
import { BrowserView, MobileView } from 'react-device-detect'
import { type SidebarInterface } from '../../../util/interface/SidebarInterface'
import MenuList from './MenuList'
import { drawerWidth } from '../../../util/constant/ThemeConstants'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  //padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarInterface) => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const drawer = (
    <>
      <BrowserView>
        <MenuList />
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
    </>
  )

  const container = window !== undefined ? () => window.document.body : undefined

  return (
    <Drawer
      container={container}
      variant={matchUpMd ? 'persistent' : 'temporary'}
      anchor='left'
      open={drawerOpen}
      onClose={drawerToggle}
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderRight: 'none',
        }
      }}
      ModalProps={{ keepMounted: true }}
      color='inherit'
    >
      <DrawerHeader>
        <IconButton onClick={drawerToggle}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      {drawer}
    </Drawer>
  )
}

export default Sidebar
