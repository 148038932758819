import { IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
// import NotificationSection from './Notification'
import ProfileSection from './ProfileSection'
import { useAppSelector } from '../../../../app/hook';
import MenuIcon from '@mui/icons-material/Menu';
import { ColorModeContext } from '../../../../App';

const Header = ({ handleLeftDrawerToggle, isOpened }: { handleLeftDrawerToggle: () => void, isOpened: boolean }): JSX.Element => {
  const theme = useTheme()
  const colorMode = React.useContext(ColorModeContext)
  const title = useAppSelector((state) => state.mainLayout.title)

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleLeftDrawerToggle}
        edge="start"
        sx={{ mr: 2, ...(isOpened && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h4" component="div" sx={{ flexGrow: 1, marginLeft: "160px", mt: 1 }}>
        {title}
      </Typography>
      {/* <NotificationSection /> */}
      <IconButton onClick={colorMode.toggleColorMode} edge="start">
        {theme.palette.mode === 'light' ? (<LightModeIcon />) : (<DarkModeIcon />)}
      </IconButton>
      <ProfileSection />
    </>
  )
}

export default Header
