import { useRoutes } from 'react-router-dom'
import MainRoutes from './MainRoute'
import { auth } from '../util/config/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

export default function Routes(): JSX.Element | null {
  const [user] = useAuthState(auth)

  return (
    useRoutes(MainRoutes(user))
  )
}
