import { Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles'
import React from 'react'
import { drawerWidth } from '../../../util/constant/ThemeConstants'
import Header from './header'
import Sidebar from '../Sidebar'
import { Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hook'
import { openSidebar } from '../../../redux/mainLayout/mainLayoutSlice'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(({ theme, open }) => ({
  ...{
    width: '100%',
    minHeight: 'calc(100vh - 88px)',
    flexGrow: 1,
    padding: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '70px',
    marginRight: '20px'
  },
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))


interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const MainLayout = (): JSX.Element => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const leftDrawerOpened = useAppSelector((state) => state.mainLayout.isOpened)
  const dispatch = useAppDispatch()
  const handleDrawerToggle = (): void => {
    dispatch(openSidebar(!leftDrawerOpened))
  }

  return (
    <Box sx={{ display: 'flex', paddingLeft: 20, paddingRight: 20 }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position='fixed'
        color='transparent'
        elevation={0}
        open={leftDrawerOpened}
        sx={{
          // transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
          backdropFilter: "blur(20px)"
        }}
      >
        <Toolbar
          sx={{ backdropFilter: "blur(20px)" }}
        >
          <Header handleLeftDrawerToggle={handleDrawerToggle} isOpened={leftDrawerOpened} />
        </Toolbar>
      </AppBar>

      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleDrawerToggle} />

      <Main theme={theme} open={leftDrawerOpened}>
        {/* <Breadcrumbs separator={KeyboardArrowRightOutlinedIcon} navigation={navigation} icon title rightAlign /> */}
        <Outlet />
      </Main>
    </Box>
  )
}

export default MainLayout
