import { Typography } from '@mui/material'
import React from 'react'
import { type CollapseInterface } from '../../../../util/interface/CollapseInterface'
import menuItems from '../../Menu-Item'
import NavGroup from './NavGroup'

const MenuList = () => {
  const navItems = menuItems.items.map((item: CollapseInterface) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Menu Items Error
          </Typography>
        )
    }
  })

  return <>{navItems}</>
}

export default MenuList
