import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { colorSchemeKey } from "../../util/constant/ThemeConstants";
import { Asset, AssetResposne } from "../../util/interface/DataInteface";

interface assetsState {
    pickedAsset: Asset
    otherAssets: Asset[]
    loaded: boolean
}

const initialState: assetsState = {
    pickedAsset: {} as Asset,
    otherAssets: [],
    loaded: false
}

const assetsSlice = createSlice({
    name: 'assetSummary',
    initialState: initialState,
    reducers: {
        onAssetNameChange: (state, action: PayloadAction<string>) => {
            const previousAsset = state.pickedAsset
            const pickedAsset = state.otherAssets.find((asset) => asset.name === action.payload)
            state.otherAssets = state.otherAssets.filter((asset) => asset.name !== action.payload)
            state.otherAssets = [...state.otherAssets, previousAsset]
            state.pickedAsset = pickedAsset ? pickedAsset : state.pickedAsset
        },
        onAssetLoad: (state, action: PayloadAction<AssetResposne>) => {
            if(!state.loaded) {
                state.pickedAsset = action.payload.asset[0]
                state.otherAssets = action.payload.asset.slice(1)
                state.loaded = true
            } else {
                const pickedAsset = action.payload.asset.find((asset) => asset.name === state.pickedAsset.name)
                const otherAssets = action.payload.asset.filter((asset) => asset.name !== state.pickedAsset.name)
                state.pickedAsset = pickedAsset ? pickedAsset : state.pickedAsset
                state.otherAssets = otherAssets
            }
        }
    }
})

export const { onAssetNameChange, onAssetLoad } = assetsSlice.actions

export default assetsSlice.reducer