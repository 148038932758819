import { Avatar, Card, CardContent, Chip, ClickAwayListener, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Switch, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Transitions from '../../../../extend/Transition'
import MainCard from '../../../../templete/cards/MainCard'
import { Box, Stack } from '@mui/system'
import { auth } from '../../../../../util/config/firebase'

const ProfileSection = (): JSX.Element => {
    const theme = useTheme()
    const navigate = useNavigate()

    const [sdm, setSdm] = useState(true)
    // const [value, setValue] = useState('')
    const [notification, setNotification] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [open, setOpen] = useState(false)

    const anchorRef = useRef<HTMLDivElement>(null)
    const handleLogout = async (): Promise<void> => {
        auth.signOut()
    }

    const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | TouchEvent): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as Node))
        {
            return
        }
        setOpen(false)
    }

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, route: string): void => {
        setSelectedIndex(index)
        handleClose(event)

        if (route != null && route !== '')
        {
            navigate(route)
        }
    }

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen)
    }

    const prevOpen = useRef<boolean>(open)
    useEffect(() => {
        if (prevOpen.current && !open && anchorRef.current)
        {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...{
                                width: '34px',
                                height: '34px',
                                fontSize: '1.2rem'
                            },
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup='true'
                        color='inherit'
                    >
                        <AccountCircleIcon />
                    </Avatar>
                }
                label={<SettingsOutlinedIcon />}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
            />
            <Popper
                placement='bottom-end'
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper sx={{ borderRadius: '12px' }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} content={false} boxShadow shadow={theme.shadows[16]} customSx={{ boxShadow: theme.shadows[16] }} other={{ elevation: 16 }}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction='row' spacing={0.5} alignItems='center'>
                                                <Typography variant='h6'>Good Morning,</Typography>
                                                <Typography component='span' variant='h6' sx={{ fontWeight: 400 }}>
                                                    Cedric Tse
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    <Box sx={{ p: 2 }}>
                                        <List
                                            component='nav'
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: '12px' }}
                                                selected={selectedIndex === 0}
                                                onClick={(event) => { handleListItemClick(event, 0, '/setting') }}
                                            >
                                                <ListItemIcon>
                                                    <SettingsOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant='body2'>Account Settings</Typography>} />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: '12px' }}
                                                selected={selectedIndex === 1}
                                                onClick={(event) => { handleListItemClick(event, 1, '/user/social-profile/posts') }}
                                            >
                                                <ListItemIcon>
                                                    <AccountCircleIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Grid container spacing={1} justifyContent='space-between'>
                                                            <Grid item>
                                                                <Typography variant='body2'>Profile</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Chip
                                                                    label='02'
                                                                    size='small'
                                                                    sx={{
                                                                        bgcolor: theme.palette.warning.dark,
                                                                        color: theme.palette.background.default
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: '12px' }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <LogoutOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant='body2'>Logout</Typography>} />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    )
}

export default ProfileSection
