import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import { type CollapseInterface } from '../../../util/interface/CollapseInterface'

const icons = { GridViewOutlinedIcon }

const dashboard: CollapseInterface = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.GridViewOutlinedIcon,
      breadcrumbs: false
    }
  ]
}

export default dashboard
