import { createEntityAdapter, createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface MainLayoutState {
  isOpened: boolean,
  lightMode: boolean,
  title: string
}

const mainLayoutAdapter = createEntityAdapter<MainLayoutState>()

const mainLayoutSlice = createSlice({
  name: 'mainLayout',
  initialState: mainLayoutAdapter.getInitialState({
    isOpened: true,
    lightMode: true,
    title: "Dashboard"
  }),
  reducers: {
    openSidebar: (state, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload
    },
    switchTheme: (state, action: PayloadAction<boolean>) => {
      state.lightMode = action.payload
    },
    enteringPage: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    }
  }
})

export const { openSidebar, switchTheme, enteringPage } = mainLayoutSlice.actions

export default mainLayoutSlice.reducer
