import React, { Suspense } from 'react'

import Loader from './Loader'

const Ladable = (Component: React.FC) => function Ladable(props: object) {
    return (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    )
}

export default Ladable
