import React from "react";
import MainCard from "../templete/cards/MainCard";
import { Grid, Typography } from "@mui/material";
import ErrorIcon from "../../assets/icon/error.svg"

const Error = () => {
    return (
        <MainCard customSx={{
            width: '100%',
            height: '100%'
        }}>
            <Grid container justifyContent='center' alignContent='center'>
                <img src={ErrorIcon} height='25%' width='25%' alt="Error" />
            </Grid>
            <Grid container justifyContent='center'>
                <Typography>
                    Opps, looks like something run wrong. Please comeback later.
                </Typography>
            </Grid>
        </MainCard>
    )
}

export default Error