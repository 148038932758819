import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { endPoint, mockEndPoint } from '../../service/constant/endpoint'
import { AssetResposne, PaginationRecordResponse, PaymentRecordResponse, PaymentSummaryResponse, SingleAssetResposne, SpendingResponse } from '../../util/interface/DataInteface'
import { auth } from '../../util/config/firebase'
import { onAssetLoad } from '../assets/assetsSlice'


export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ENV === "DEV" ? mockEndPoint : endPoint,
    prepareHeaders: async (headers) => {
      await auth.currentUser?.getIdToken()
        .then((token) => {
          headers.set('Authorization', `Bearer ${token}`)
          //console.log("Authorization header inside is: " + headers.get("Authorization"))x
        })
      //console.log("Authorization header is: " + headers.get("Authorization"))
      return headers
    }
  }),
  tagTypes: ['InsertRecord', 'InsertAsset'],
  endpoints: builder => ({
    insertRecord: builder.mutation({
      query: recordData => ({
        url: '/payment-record',
        method: 'POST',
        body: recordData
      }),
      invalidatesTags: ['InsertRecord']
    }),
    editRecord: builder.mutation({
      query: recordData => ({
        url: '/payment-record',
        method: 'PUT',
        body: recordData
      }),
      invalidatesTags: ['InsertRecord']
    }),
    fetchRecord: builder.query<PaymentRecordResponse, { month: number, year: number, description?: string }>({
      query: ({ month, year, description }) => ({
        url: `/payment-record/${year}/${month}` + (description ? `?description=${description}` : ""),
        method: 'GET'
      }),
      providesTags: ['InsertRecord']
    }),
    fetchPaginationRecord: builder.query<PaginationRecordResponse, { pageNumber: number, pageSize: number, description?: string }>({
      query: ({ pageNumber, pageSize, description }) => ({
        url: `/payment-record/${pageNumber}?pageSize=${pageSize}` + '&' + (description ? `description=${description}` : ""),
        method: 'GET'
      }),
      providesTags: ['InsertRecord']
    }),
    fetchSummary: builder.query({
      query: month => ({
        url: `/record/summary?month=${month}`,
        method: 'GET'
      }),
      providesTags: ['InsertRecord']
    }),
    fetchAsset: builder.query<AssetResposne, {}>({
      query: () => ({
        url: `/asset`,
        method: 'GET'
      }),
      async onQueryStarted({ }, { dispatch, queryFulfilled }) {
        try
        {
          const { data } = await queryFulfilled
          dispatch(onAssetLoad(data))
        } catch (err)
        {
          console.log(err)
        }
      },
      providesTags: ['InsertAsset']
    }),
    fetchSingleAsset: builder.query<SingleAssetResposne, string>({
      query: assetName => ({
        url: `/asset?assetName=${assetName}`,
        method: 'GET'
      })
    }),
    fetchAssetRecentRecord: builder.query<PaymentRecordResponse, string>({
      query: assetName => ({
        url: `/payment-record/recent?search=${assetName}&page=10`,
        method: 'GET'
      })
    }),
    registerNewAsset: builder.mutation({
      query: registerData => ({
        url: '/asset',
        method: 'POST',
        body: registerData
      }),
      invalidatesTags: ['InsertAsset']
    }),
    fetchSpending: builder.query<SpendingResponse, { month: number, year: number }>({
      query: ({ month, year }) => ({
        url: `/payment-record/spending/${year}/${month}`,
        method: 'GET'
      }),
      providesTags: ['InsertRecord']
    }),
    fetchPaymentSummary: builder.query<PaymentSummaryResponse, { month: number, year: number }>({
      query: ({ month, year }) => ({
        url: `/payment-record/summary/${year}/${month}`,
        method: 'GET'
      })
    })
  })
})

export const {
  useInsertRecordMutation,
  useEditRecordMutation,
  useFetchRecordQuery,
  useFetchPaginationRecordQuery,
  useFetchSummaryQuery,
  useFetchAssetQuery,
  useLazyFetchAssetRecentRecordQuery,
  useFetchSingleAssetQuery,
  useRegisterNewAssetMutation,
  useFetchSpendingQuery,
  useFetchPaymentSummaryQuery
} = apiSlice
